<template>
  <div class="image-grid-container">
    <div class="sort-options">
      <!-- Sort Options Content -->
      <label for="sort-method">Sort by:</label>
      <select id="sort-method" v-model="selectedSort" @change="sortItems">
        <option value="viewerCount">Viewer Count</option>
        <option value="startTime">Start Time</option>
        <option value="random">Random</option>
      </select>

      <div v-if="selectedSort !== 'random'" class="sort-order">
        <label>
          <input type="radio" value="descending" v-model="sortOrder" @change="sortItems" />
          Descending
        </label>
        <label>
          <input type="radio" value="ascending" v-model="sortOrder" @change="sortItems" />
          Ascending
        </label>
      </div>

      <!-- Search box on the same line -->
      <div class="search-box">
        <input id="search-input" v-model="searchQuery" @input="filterItems" type="text" placeholder="Search by name, category, or title..." />
      </div>
    </div>
    <div class="image-grid">
      <div v-for="item in sortedItems" :key="item.platformID" :class="{'faded': !item.isLive}" class="grid-item">
        <a :href="`https://kick.com/${item.streamerName}`" target="_blank" rel="noopener noreferrer">
          <!-- Thumbnail Image -->
          <img :src="`/StreamThumbnails/${item.thumbnailFileName}`" :alt="item.sessionTitle" />
          <!-- Live Status Badge -->
          <div v-if="item.isLive" class="live-badge">LIVE</div>
          <!-- Live Time (xx:xx) if live -->
          <div v-if="item.isLive" class="live-time">{{ formatLiveTime(item.startTime) }}</div>
          <!-- Offline Time if not live -->
          <div v-else class="offline-time">{{ calculateLastSeen(item.startTime, item.duration) }}</div>
        </a>
        <div class="info">
          <h3 @click.stop="showPopup(item)" class="streamer-name">{{ item.streamerName }}</h3>
          <h2 class="category">{{ item.category }}</h2>
          <p>{{ item.sessionTitle }}</p>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div v-if="popupVisible" class="popup">
      <button class="popup-close" @click="hidePopup">&times;</button>
      <img :src="`/StreamThumbnails/${popupItem.thumbnailFileName}`" class="popup-thumbnail" />
      <div class="popup-content">
        <h2>{{ popupItem.streamerName }}</h2>
        <p>{{ popupItem.sessionTitle }}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ImageGrid',
  data() {
    return {
      items: [],
      selectedSort: 'viewerCount',
      sortOrder: 'descending',
      searchQuery: '',
      popupVisible: false,
      popupItem: null,
      popupWidth: window.innerWidth / 2,
      popupHeight: window.innerHeight / 2,
    }
  },
  computed: {
  sortedItems() {
    let items = [...this.items];

    // Filter items based on search query
    if (this.searchQuery) {
      const query = this.searchQuery.toLowerCase();
      items = items.filter(item =>
        item.streamerName.toLowerCase().includes(query) ||
        item.category.toLowerCase().includes(query) ||
        item.sessionTitle.toLowerCase().includes(query)
      );
    }

    // Sorting logic remains the same
    if (this.selectedSort === 'random') {
      const liveItems = items.filter(item => item.isLive);
      const offlineItems = items.filter(item => !item.isLive);

      liveItems.sort(() => Math.random() - 0.5);
      offlineItems.sort(() => Math.random() - 0.5);

      return [...liveItems, ...offlineItems];
    } else {
      items.sort((a, b) => {
        if (b.isLive !== a.isLive) return b.isLive - a.isLive;

        const valueA = this.selectedSort === 'viewerCount' ? a.viewerCount : new Date(a.startTime);
        const valueB = this.selectedSort === 'viewerCount' ? b.viewerCount : new Date(b.startTime);

        return this.sortOrder === 'ascending' ? valueA - valueB : valueB - valueA;
      });

      return items;
    }
  }
}
,
  methods: {
    async fetchData() {
      try {
        const response = await fetch('LsnStreamers.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.items = data;
        this.sortItems(); // Sort the items initially based on the default sort method
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    sortItems() {
      if (this.selectedSort === 'random') {
        const liveItems = this.items.filter(item => item.isLive);
        const offlineItems = this.items.filter(item => !item.isLive);

        liveItems.sort(() => Math.random() - 0.5);
        offlineItems.sort(() => Math.random() - 0.5);

        this.items = [...liveItems, ...offlineItems];
      } else {
        this.items.sort((a, b) => {
          if (b.isLive !== a.isLive) return b.isLive - a.isLive;

          const valueA = this.selectedSort === 'viewerCount' ? a.viewerCount : new Date(a.startTime);
          const valueB = this.selectedSort === 'viewerCount' ? b.viewerCount : new Date(b.startTime);

          return this.sortOrder === 'ascending' ? valueA - valueB : valueB - valueA;
        });
      }
    },
    showPopup(item) {
      this.popupItem = item;
      this.popupVisible = true;
    },
    hidePopup() {
      this.popupVisible = false;
      this.popupItem = null;
    },
    formatLiveTime(startTime) {
    const start = new Date(startTime);
    const now = new Date();
    const durationMs = now.getTime() - start.getTime();

    const hours = Math.floor(durationMs / 3600000);
    const minutes = Math.floor((durationMs % 3600000) / 60000);

    // Format time as "xx:xx" (e.g., "02:45")
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  },
    calculateLastSeen(startTime, duration) {
      const start = new Date(startTime);
      const endOfLastStream = start.getTime() + duration;
      const now = new Date();
      const durationMs = now.getTime() - endOfLastStream;

      const days = Math.floor(durationMs / 86400000);
      const hours = Math.floor((durationMs % 86400000) / 3600000);
      const minutes = Math.floor((durationMs % 3600000) / 60000);

      if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      }
    },
    updatePopupDimensions() {
      this.popupWidth = window.innerWidth / 2;
      this.popupHeight = window.innerHeight / 2;
    }
  },
  mounted() {
    this.fetchData();
    window.addEventListener('resize', this.updatePopupDimensions);
    this.updatePopupDimensions();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updatePopupDimensions);
  }
}
</script>


<style scoped>
.image-grid-container {
  padding: 16px;
}

.sort-options, .search-box {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
  color: white;
}

.sort-order {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-box {
  margin-left: auto; /* Push the search box to the right side on larger screens */
}

#search-input {
  padding: 8px;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .sort-options {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .search-box {
    margin-left: 0; /* Reset the margin to prevent the right alignment */
    width: 100%; /* Ensure the search box takes full width */
  }

  #search-input {
    max-width: 100%; /* Allow search input to expand to full width */
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;
  background-color: black;
  padding: 16px;
  color: gray;
}

.grid-item {
  position: relative;
  border: 1px solid #333;
  border-radius: 8px;
  overflow: visible; /* Ensure content can overflow the box */
  text-align: center;
  background-color: #222;
  transition: transform 0.3s ease; /* Optional: Add a smooth transition for the entire grid item */
  box-sizing: border-box; /* Make sure border is part of the grid item's dimensions */
}

.grid-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


.live-badge {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 2px 4px;
  background-color: orange;
  color: black;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.65em;
}

.live-time, .offline-time {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px 4px;
  background-color: black;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.65em;
}

.faded img {
  filter: grayscale(100%) brightness(50%); /* Grayscale and darken offline images */
}


.info {
  position: relative;
  z-index: 2;
  padding: 8px;
}

.info h3 {
  color: lightgray; /* Color for session title */
  cursor: pointer; /* Change cursor to pointer for clickable items */
  margin: 4px 4px; /* Reducing the top and bottom margins to 4px */
}

.info p {
  color: gray; /* Color for general text */
  margin: 4px 4px; /* Reducing the top and bottom margins to 4px */
  padding: 4px; /* Remove any padding if present */
}

.category {
  font-size: 0.85em; /* Slightly smaller font size */
  color: lightgray; /* Optional: you can style it to be more subtle */
}

.live-status {
  color: white; /* Highlight color for live status */
}

.viewer-count {
  color: lightgray; /* Color for viewer count text */
}

a {
  text-decoration: none;
  color: inherit;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Faint border */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.popup-thumbnail {
  max-width: 100%;
  max-height: 50%;
  margin-bottom: 16px;
}

.popup-content {
  text-align: center;
}
</style>
